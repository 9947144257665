.app {
  display: flex;
  min-height: 100vh;
}
.sidebar {
      flex: 0 0 240px;
      background-color: lightblue;
  }

.content {
      flex: 1;
      padding: 40px;
  }
  input[type="file"] {
    display: none; 
    /* important, otherwise default button will be displayed */
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: flex;
    padding: 6px 12px;
    cursor: pointer;
    margin-bottom: 0px;
    /* width: 360px; */
    background: whitesmoke;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 4px;
}
